<template>
    <div class="menu-wrapper" :class="{ 'layout-sidebar-active': sidebarActive }" @click="onMenuClick" @mouseover="onSidebarMouseOver" @mouseleave="onSidebarMouseLeave">
        <div class="sidebar-logo">
            <router-link to="/">
                <img :src="origin + '/assets/layout/images/hamburger.svg'" alt="" class="sidebar-logo-icon" />
                <!-- <strong style="padding-left: 15px; font-size: 20px">{{ title }}</strong> -->
                <img v-if="title" :src="origin + '/assets/layout/images/tech-square-logo.svg'" alt="tech-square"  class="sidebar-logo-main" />
            </router-link>
            <a class="sidebar-pin" @click="onToggleMenu($event)">
                <span class="pin"></span>
            </a>
        </div>

        <div class="layout-menu-container">
            <AppSubmenu
                class="layout-menu"
                :items="super_admin"
                :layoutMode="layoutMode"
                :parentMenuItemActive="true"
                :menuActive="menuActive"
                :mobileMenuActive="mobileMenuActive"
                :root="true"
                @menuitem-click="onMenuItemClick"
                @root-menuitem-click="onRootMenuItemClick"
            />

            <div @click="logout">
                <div>
                    <span class="logout-btn">
                        <i class="pi pi-sign-out"></i>
                        {{ mouse_over ? 'Logout' : '' }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
.logout-btn {
    position: absolute;
    display: block;
    width: 100%;
    padding: 15px 20px;
    color: #000;
    font-size: 16px;
    font-weight: 500;
    background-color: #fff;
    cursor: pointer;
    bottom: 0;
}

.logout-btn:hover {
    color: var(--primary-color);
}

.logout-btn i {
    font-size: 24px;
    vertical-align: middle;
}
</style>

<script>
import AppSubmenu from './AppSubmenu';
import { Token } from '@/service/Token';

export default {
    name: 'AppMenu',
    emits: ['menu-click', 'menuitem-click', 'root-menuitem-click', 'toggle-menu', 'sidebar-mouse-over', 'sidebar-mouse-leave'],
    props: {
        sidebarActive: Boolean,
        sidebarStatic: Boolean,
        layoutMode: String,
        menuActive: Boolean,
        mobileMenuActive: Boolean,
    },
    data() {
        return {
            origin: window.location.origin,
            title: false,
            user_type: '',
            user_id: '',
            authority_id: '',
            mouse_over: false,
            menus: [],
            admin_user: [],
            super_admin: [
                //dashboard
                // { label: '계기반', icon: 'pi pi-th-large', to: '/' },
                //Membership Management
                {
                    label: '회원 관리',
                    iconImg: '/assets/layout/images/memberIcon.svg',
                    items: [
                        { label: '회원 관리', icon: 'pi pi-list', to: '/MemberManagement' },
                        { label: '기업 관리', icon: 'pi pi-list', to: '/CorporateManagement' },
                        { label: '멘토 관리', icon: 'pi pi-list', to: '/mentor-management' },
                        { label: '기업 상세 관리', icon: 'pi pi-list', to: '/company-details' },
                    ],
                },

                //Bussiness Management
                {
                    name: 'BusinessManagement',
                    label: '사업 관리',
                    icon: 'pi pi-th-large',
                    // iconImg: '/assets/layout/images/ContentIcon.svg',
                    items: [
                        { label: '멘토링 관리', icon: 'pi pi-list', to: '/mentoring-management' },
                        { label: '멘토비 지급현황 관리', icon: 'pi pi-list', to: '/mentor-payment-status' },
                        { label: '공급기업 추천 관리', icon: 'pi pi-list', to: '/supplier-recommendation' },
                        { label: '진행 사업 관리', icon: 'pi pi-list', to: '/business-pogress' },
                        { label: '상생 신청 관리', icon: 'pi pi-list', to: '/winwin-business-management'},
                        { label: '만족도조사 문항관리', icon: 'pi pi-list', to: '/satisfactionManagement' },
                        { label: '만족도조사 결과', icon: 'pi pi-list', to: '/satisfactionResult' },
                    ],
                },
                //Smart Device
                {
                    name: 'SmartDevice',
                    label: '스마트디바이스',
                    icon: 'pi pi-th-large',
                    // iconImg: '/assets/layout/images/ContentIcon.svg',
                    items: [
                        { label: '디바이스 관리', icon: 'pi pi-list', to: '/device-management' },
                        { label: '납품 현황 관리', icon: 'pi pi-list', to: '/delivery-status' },
                        { label: '견적 관리', icon: 'pi pi-list', to: '/qoute-management' },
                    ],
                },
                //중고 설비
                {
                    name: 'UsedFacilityManagement',
                    label: '중고 설비',
                    icon: 'pi pi-th-large',
                    // iconImg: '/assets/layout/images/MenuIcon.svg',
                    items: [
                        { label: '거래 관리', icon: 'pi pi-list', to: '/transactionManagement' },
                        { label: '현황', icon: 'pi pi-list', to: '/statusManagement' },
                    ],
                },
                //Content Management
                {
                    name: 'ContentManagement',
                    label: '컨텐츠 관리',
                    iconImg: '/assets/layout/images/ContentIcon.svg',
                    items: [
                        { label: '스마트 팩토리', icon: 'pi pi-list', to: '/smart-factory' },
                        { label: '우수 사례', icon: 'pi pi-list', to: '/best-practices' },
                        { label: '스마트 디바이스 소개', icon: 'pi pi-list', to: '/smart-devices' },
                        { label: '공급 기업 소개', icon: 'pi pi-list', to: '/supplier-introduction' },
                        { label: '간편 수준 진단', icon: 'pi pi-list', to: '/simple-level-diagnosis' },
                        { label: '간편 수준 진단 보고서', icon: 'pi pi-list', to: '/simple-level-diagnosis-report' },
                        { label: '정부보조금 계산', icon: 'pi pi-list', to: '/gov-subsidy' },
                        { label: '1:1 문의', icon: 'pi pi-list', to: '/oneone-inquiry' },
                        { label: 'FAQ', icon: 'pi pi-list', to: '/faq' },
                        { label: '공지사항', icon: 'pi pi-list', to: '/NoticeManagement' },
                        { label: '전시회 백그라운드 컨텐츠', icon: 'pi pi-list', to: '/video-to-simple-report' },
                        { label: '팝업관리', icon: 'pi pi-list', to: '/popup' },
                    ],
                },
                //Notice Management
                {
                    name: 'NotificationManagement',
                    label: '알림 관리',
                    icon: 'pi pi-th-large',
                    // iconImg: '/assets/layout/images/ContentIcon.svg',
                    items: [
                        { label: '메일 관리', icon: 'pi pi-list', to: '/mail-management' },
                        { label: '문자 관리', icon: 'pi pi-list', to: '/text-management' },
                        { label: '알림 이력', icon: 'pi pi-list', to: '/notification-history' },
                    ],
                },
                //마스터 관리
                {
                    name: 'NotificationManagement',
                    label: '마스터 관리',
                    icon: 'pi pi-th-large',
                    // iconImg: '/assets/layout/images/ContentIcon.svg',
                    items: [
                        { label: '약관 관리', icon: 'pi pi-list', to: '/terms-and-conditions' },
                        { label: '관리자 관리', icon: 'pi pi-list', to: '/manager' },
                        //Menu Management
                        { label: '공통코드관리', icon: 'pi pi-th-large', to: '/common-code-list' },
                    ],
                },
                // Statistics Management
                {
                    name: 'StatisticsManagement',
                    label: '통계 관리',
                    icon: 'pi pi-th-large',
                    // iconImg: '/assets/layout/images/ContentIcon.svg',
                    items: [{ label: '간편수준진단 이력', icon: 'pi pi-list', to: '/report-result-history' }],
                },
                //Menu Management
                { label: '메뉴 관리', iconImg: '/assets/layout/images/MenuIcon.svg', to: '/menu-management' },
            ],
        };
    },

    methods: {
        logout() {
            let token = new Token();
            token.remove('user');
            token.remove('refreshToken');
            this.$router.push({ name: 'login' });
        },
        onSidebarMouseOver() {
            if (this.layoutMode === 'sidebar' && !this.sidebarStatic) {
                this.$emit('sidebar-mouse-over');
                this.title = true;
                this.mouse_over = true;
            }
        },
        onSidebarMouseLeave() {
            if (this.layoutMode === 'sidebar' && !this.sidebarStatic) {
                setTimeout(() => {
                    this.$emit('sidebar-mouse-leave');
                    this.title = false;
                    this.mouse_over = false;
                }, 250);
            }
        },
        onToggleMenu(event) {
            this.$emit('toggle-menu', event);
        },
        onMenuClick(event) {
            this.$emit('menu-click', event);
        },
        onMenuItemClick(event) {
            this.$emit('menuitem-click', event);
        },
        onRootMenuItemClick(event) {
            this.$emit('root-menuitem-click', event);
        },
    },
    components: { AppSubmenu },
};
</script>
